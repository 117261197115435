@media screen and (max-width: 1000px) {
    .contact-left h2{
        font-size: 2.5em;
    }
    .contact-left .strath-phone h3{
        font-size: 1.2em;
    }
}

@media screen and (max-width: 850px) {
    .contact-right{
        width: 50%;
    }
    .contact-right form input{
        width: 80%;
    }
    .contact-right form textarea{
        width: 80%;
    }
    .contact-left .strath-phone h3{
        font-size: 1em;
    }
    .contact-left .strath-phone a{
        font-size: 1em;
    }
    .contact-left .strath-phone h4{
        font-size: 1em;
    }
}

@media screen and (max-width: 670px){
    .contact-bottom{
        flex-direction: column;
    }
    .contact-left{
        width: 100%;
    }
    .contact-right{
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .contact-top h1{
        font-size: 2.3em;
       
    }
    .contact-right form{
        padding: 10px;
        gap: 0;
    }
    .contact-right form label{
        font-size: 1em;
        width: 100%;
    }
    .contact-right form input{
        width: 90%;
    }
    .contact-right form textarea{
        width: 90%;
    }
    .contact-left h2{
        font-size: 2em;
    }
    .contact-left .strath-email h3{
        font-size: 1.2em;
    }
    .contact-left .strath-address h3{
        font-size: 1.2em;
    }
    .contact-left .strath-hours h3{
        font-size: 1.2em;
    }
}