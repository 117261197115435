@media screen and (max-width: 1250px) {
    .hero-info{
        gap: 80px;
    }
}

@media screen and (max-width: 800px) {
    .hero-info h2{
        margin-top: 30px;
        font-size: 3em;
    }
    .hero-info h1{
        font-size: 5em;
    }
}

@media screen and (max-width: 690px) {
    .hero-info{
        padding-bottom: 20px;
    }
    .hero-content{
        width: 100%;
        margin: 0;
    }
    .hero-info h1{
        padding: 20px;
    }
}

@media screen and (max-width: 450px) {
    
    .hero-info h1{
        font-size: 4em;
    }
    .hero-info h2{
        font-size: 2.5em;
    }
    .hero-info{
        gap: 40px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 350px){
    
    .hero-info h1{
        font-size: 3.5em;
        font-weight: 800;
    }
    .hero-info h2{
        font-size: 2.2em;
        font-weight: 600;
    }
    .hero-info button{
        font-size: 0.8em;
    }
}

@media screen and (max-width: 300px){
    .hero-info h1{
        font-size: 3em;
    }
    .hero-info{
        margin-top: 0;
        padding: 0;
    }
}