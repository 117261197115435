.know-us-container{
    height: 100%;
    width: 100vw;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.know-us-top{
height: 100%;
width: 100%;
text-align: center;
padding: 20px;
display: flex;
justify-content: center;
margin: 0;
padding: 0;
}
.know-us-top h2{
    padding: 2rem;
    font-size: 3.5em;
    text-align: center;
}
.know-us-bottom{
    width: 100%;
    height: 100%;
    display: flex;
}
.know-us-bottom .know-us-left{
    width: 40%;
    background-image: url(../../../assets/Know-Us.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 10px 10px var(--white) inset;
}
.know-us-bottom .know-us-right{
    height: 100%;
    width: 70%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.know-us-right h3{
    font-size: 2rem;
}
.know-us-right p{
    width: 100%;
    font-size: 1.1em;
    line-height: 1.6;
    letter-spacing: 1.5px;
    font-weight: 500;
}