.nav-container{
    height: 70px;
    width: 100vw;
    background-color: #3f91d4;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}
.nav-content{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.nav-logo{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-logo h2{
    font-size: 2em;
}
.nav-logo a{
    color: var(--white);
}
.nav-links{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-links ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.nav-links li{
    font-weight: 500;
    transition: 0.3s ease-in-out;
}
.nav-links li a{
    color: var(--white);
    position: relative;
    transition: 0.3s ease-in;
    padding: 10px;
    border-radius: 10px;
}

.nav-links li a:after{
    content: '';
    position: absolute;
    background: #ffffff;
    height: 3px;
    width: 0;
    left: 0;
    bottom: -2px;   
    transition: 0.3s ease-in-out;
}

.nav-links li a:hover:after{
    width: 100%;

}

.nav-mobile{
    display: none;
}