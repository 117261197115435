@media screen and (max-width: 1060px) {
    .discount-top h2{
        font-size: 3em;
    }
    .discount-top h4{
        font-size: 1.7em;
    }
    .discount-left h3{
        font-size: 1.5em;
        padding: 10px;
    }
    .discount-left h4{
        font-size: 0.9em;
    }
    .discount-left p{
        font-size: 1em;
    }
    .discount-left button{
        padding: 10px 20px;
        font-size: 1em;
    }
}

@media screen and (max-width: 850px) {
    .discount-container{
        padding: 0;
    }
    .discount-bottom{
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }
    .discount-right .discount-image{
        box-shadow: none;
        border-radius: 0;
    }
    .discount-right{
        right: 0;
    }
    .discount-left{
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        min-width: 100vw;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .discount-top h2{
        font-size: 2.5em;
    }
    .discount-top h4{
        font-size: 1.2em;
    }
    .discount-left{
        width: 100%;
    }
    .discount-left h3{
        font-size: 1.6em;
    }
} 

@media screen and (max-width: 450px) {

    .discount-top h2{
        padding: 5px;
        font-size: 2em;
        font-weight: 600;
    }
    .discount-left h3{
        font-size: 1em;
    }
    .discount-bottom{
        padding: 5px;
    }
    .discount-container{
        padding: 5px;
        padding-bottom: 20px;
        width: 100%;
    }
} 
