@media screen and (max-width: 650px) {
    .footer-bottom{
        grid-template-columns: 1.5fr 1fr;
    }
}

@media screen and (max-width: 510px) {
    .footer-top h2{
        font-size: 1.4em;
    }
    .footer-top button{
        height: 40px;
        width: 40px;
        font-size: 35px;
        border: none;
    }
    .footer-bottom h2{
        font-size: 0.8em;
    }
    .footer-bottom h3{
        font-size: 0.8em;
    }
}

@media screen and (max-width: 420px) {
    .footer-top h2{
        font-size: 1em;
    }
    .footer-bottom h2{
        text-align: center;
    }
    .footer-bottom h3{
        text-align: center;
    }
    .footer-bottom{
        grid-template-columns: 1fr;
        gap: 0;
    }
}

