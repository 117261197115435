.discount-container{
height: 100%;
width: 100vw;
position: relative;
background: var(--lightgreen);
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;  
padding-top: 30px;
margin-bottom: -1px;
}
.discount-top{
    margin: 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}
.discount-top h2{
    font-size: 4em;
    font-weight: 500;
    padding: 20px;
}
.discount-top h2 span{
    color: var(--lightblue);
}
.discount-top h4{
    font-size: 2em;
    font-weight: 400;
    padding: 20px;
}
.discount-bottom{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;
}
.discount-left{
    height: 600px;
    width:800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    align-items: center;
    text-align: center;
    background: var(--darkgreen);
    padding: 40px;
    border: none;
    z-index: 1;
}
.discount-left h3{
    width: 100%;
    padding: 20px;
    font-size: 2em;
    color: #FFFFFF;
}
.discount-left h4{
    font-size: 1.2em;
    font-weight: 400;
    color: var(--lightblue);
}
.discount-left p{
    font-size: 1.2em;
    font-weight: 600;
    max-width: 400px;
    color: #FFFFFF;
}
.discount-left button{
    height: 50px;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.2em;
    background-color: var(--lightblue);
    transition: 0.3s ease-in-out;
    border: none;
    cursor: pointer;
}
.discount-left button a{
    color: #FFFFFF;
}
.discount-left button:hover{
    transform: scale(1.1);
}
.discount-right{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    right: 5%;
  
}
.discount-right .discount-image{
    height: 600px;
    max-width: 100%;
    width: 100%;
    background-image: url(../../assets/Strathcona-Front.jpg); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 22px 22px var(--lightgreen) inset;
    border-radius: 20px;
}

.custom-shape-divider-bottom-1662165651 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1662165651 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1662165651 .shape-fill {
    fill: #FFFFFF;
}

