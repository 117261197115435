@media screen and (max-width:1000px) {
    .nav-logo h2{
        font-size: 1.5em;
    }
    .nav-links ul{
        gap: 10px;
    }

}

@media screen and (max-width:800px) {
 
    .nav-links{
        transform: translateX(1000%);
        transition: all 1s;
        width: 100%;
    }
    .nav-links ul{
        position: absolute;
        top: 60px;
        left: 0;
        display: flex;
        flex-direction: column;  
        background: var(--lightblue);
        height: 100vh;
        width: 100vw; 
        gap: 50px; 
        
    }
    .nav-links.open{
        transform: translateX(-100%);
        
    }
    .nav-links li{
        font-size: 1rem;
    }
    .nav-mobile{
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .nav-mobile .bars{
        position: relative;
        width: 32px;
        height: 2px;
        background: var(--white);
    }
    .nav-mobile .bars::before, .nav-mobile .bars::after{
        content:'';
        position: absolute;
        height: 2px;
        background: var(--white);
        border-radius: 2px;
        transition: all .45s ease-in-out;
    }
    .nav-mobile .bars::before{
        width: 25px;
        transform: translateY(-8px);
        right: 0;
    }
    .nav-mobile .bars::after{
        width: 32px;
        transform: translateY(8px);
    }
    .nav-mobile.open .bars{
        transform: translateX(-40px);
        background: transparent;
    }
    .nav-mobile.open .bars::before{
        width: 32px;
        transform: rotate(45deg);
    }

    .nav-mobile.open .bars::after{
        transform: rotate(-45deg);
    }

}

@media screen and (max-width: 600px) {
    .nav-content{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 400px) {
    .nav-logo h2{
        font-size: 1em;
    }
}

