@media screen and (max-width: 1400px) {
    .our-story-bottom{
        height: 1300px;
    }
    .our-story-bottom .our-story-card{
        width: 500px;
    }
    #onwards{
        top: 750px;
    }
}

@media screen and (max-width: 1000px) {
    .our-story-bottom{
        height: 1500px;
    }
    .our-story-bottom .our-story-card{
        width: 400px;
    }
    #race{
        top: 480px;
    }
    #onwards{
        top: 850px;
    }
}

@media screen and (max-width: 800px) {
    .our-story-bottom{
        height: 100%;
    }
    .our-story-bottom .our-story-card{
        height: 100%;
        width: 80%;
    }
    #beginning{
        position: relative;
        top: 0;
        left: 0;
    }
    #starting{
        position: relative;
        top: 0;
        left: 0;
    }
    #race{
        position: relative;
        top: 0;
        left: 0;
    }
    #onwards{
        position: relative;
        top: 0;
        left: 0;
    }

    .our-story-banner{
        height: 150px;
    }
}

@media screen and (max-width: 450px) {
    .our-story-top h2{
        font-size: 3em;
    }
    .our-story-bottom .our-story-card{
        width: 90%;
    }
}

@media screen and (max-width: 350px) {
    .our-story-top h2{
        font-size: 2em;
    }
    .our-story-card h2{
        font-size: 2em;
    }
    .our-story-card h3{
        font-size: 1.5em;
    }
    .our-story-card p{
        font-size: 0.8em;
    }
}
