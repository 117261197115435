@media screen and (max-width: 1300px) {
    .map-left iframe{
        width: 500px;
        height: 500px;
    }
}

@media screen and (max-width: 1100px) {
    .map-right h2{
        font-size: 2em;
    }
    .map-right .sights-gallery{
        gap: 0;
    }
    .sights-gallery .sights-falls{
        height: 160px;
        width: 160px;
    }
    .sights-gallery .sights-fw{
        height: 160px;
        width: 160px;
    }
    .sights-gallery .sights-marina{
        height: 160px;
        width: 160px;
    }
    .map-right p{
        font-size: 1em;
    }
}

@media screen and (max-width: 850px) {
    .map-top h3{
        font-size: 1.5em;
        padding: 5px;
        width: 100%;
    }
    .map-bottom{
        flex-direction: column;
    }
    .map-left{
        width: 100%;
        box-shadow: none;
        border-radius: 0;
    }
    .map-left iframe{
        padding: 20px;
    }
    .map-right{
        width: 100%;
        padding: 5px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .map-right .sights-gallery{
        gap: 20px;
    }
    .map-right p{
        width: 90%;
    }

} 

@media screen and (max-width: 420px) {
    .map-top h3{
        font-size: 1.2em;
    }
    .map-left{
        padding: 5px;
    }
    .map-left iframe{
        width: 350px;
        height: 350px;
    }
    .map-right{
        flex-direction: column-reverse;
    }
    .map-right h2{
        font-size: 1.5em;
    }
    .map-right .sights-gallery{
        flex-direction: column;
    }
    .sights-gallery .sights-falls{
        width: 300px;
    }
    .sights-gallery .sights-fw{
        width: 300px;
    }
    .sights-gallery .sights-marina{
        width: 300px;
    }
} 