@media screen and (max-width: 1100px) {
    .date-selector input{ 
        gap: 10px;
    }
    .date-selector label{
        font-size: 1.2em;
    }
    .reservation-info input{
        gap: 10px;
    }
    .reservation-info label{
        font-size: 1.2em;
    }
    .booking-right img{
        width: 200px;
        height: 200px;
    }
    .booking-right h4{
        font-size: 1.2em;
        text-align: center;
    }
    
}

@media screen and (max-width: 940px) {
    .reservation-info label{
        font-size: 1em;
    }
    .date-selector label{
        font-size: 1em;
    }
    .booking-left h2{
        font-size: 1.8em;
    }
    .booking-left p{
        font-size: 1em;
    }
}

@media screen and (max-width: 800px) {
    .reservation-info input{
        padding: 0;
    }
    .date-selector input{
        padding: 0;
    }
    .date-selector select{
        padding: 0;
    }
    .booking-right{
        gap: 20px;
    }
    .booking-right h4{
        width: 30%;
        font-size: 1em;
        padding: 10px;
    }
}

@media screen and (max-width: 680px) {
    .reservation-info{
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 0;
        padding: 0;
        padding-top: 50px;
    }
    .reservation-info input{
        
        width: 100%;
        text-align: left;
        align-self: flex-start;
        font-size: 1.2em;
    }
    .reservation-info label{
        width: 50%;
        margin: 0 auto;
        font-size: 1.2em;
    }
    .booking-bottom{
        flex-direction: column;
    }
    .booking-left{
        width: 100%;
    }
    .booking-right{
        width: 100%;
    }
    .booking-right img{
        width: 300px;
        height: 300px;
    }
    .booking-right h4{
        width: 100%;
    }
}

@media screen and (max-width: 510px) {
    .booking-top h1{
        font-size: 3em;
    }
    .date-selector{
        height: 100%;
        width: 100%;
        flex-direction: column;
        padding: 30px;
        padding-top: 0;
    }
    .date-selector select{
        width: 100%;
        font-size: 1em;
    }
    .date-selector input{
        font-size: 1.5em;
    }
    .date-selector label{
        font-size: 1.2em;
        padding: 0;
    }
    .booking-right img{
        width: 200px;
        height: 200px;
    }
    .booking-left{
        padding: 5px;
        padding-top: 50px;
    }
    .booking-right{
        padding: 0;
    }
}

@media screen and (max-width: 400px) {
    .image-kitchen{
        flex-direction: column;
        gap: 0;
    }
    .image-dinning{
        flex-direction: column-reverse;
        gap: 0;
    }
    .image-bath{
        flex-direction: column;
        gap: 0;
    }
}