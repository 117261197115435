@media screen and (max-width: 1000px) {
    .know-us-bottom{
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    .know-us-bottom .know-us-left{
        width: 80%;
        height: 500px;
    }
    .know-us-bottom .know-us-right{
        width: 100%;
        align-items: flex-start;
        padding: 10px;
        text-align: center;
        padding-top: 50px;
    }
    .know-us-right h3{
        width: 100%;
        text-align: center;
        padding: 20px;
        font-size: 3em;
    }
    .know-us-right p{
        font-size: 1.5em;
    }
}

@media screen and (max-width: 600px) {
    .know-us-top h2{
        font-size: 3em;
    }
    .know-us-bottom .know-us-left{
        width: 100%;
        height: 300px;
    }
    .know-us-right h3{
        font-size: 2.5em;
    }
    .know-us-right p{
        font-size: 1.2em;
    }
}

@media screen and (max-width: 500px){
    .know-us-top h2{
        font-size: 2em;
    }
    .know-us-right h3{
        font-size: 2em;
    }
    .know-us-right p{
        font-size: 1em;
    }
}

@media screen and (max-width: 370px) {
    .know-us-top h2{
        font-size: 1.6em;
    }
    .know-us-bottom .know-us-left{
        height: 200px;
    }
    .know-us-right h3{
        font-size: 1.6em;
    }
}
