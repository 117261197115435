@media screen and (max-width: 1350px){
    .priorities-wrapper ul{
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 40px;
    }
}

@media screen and (max-width: 1075px){
    .priorities-container h2{
        font-size: 2.5em;
    }
    .feature-left .feature-image{
        background-position: right;
    }
    
}
@media screen and (max-width: 920px){
    .priorities-container h2{
        font-size: 2em;
    }
    .feature-wrapper{
        padding:5px;
    }
    .feature-wrapper .feature-left{
        padding: 5px;
    }
    .feature-right{
        padding: 5px;
    }
    .feature-wrapper{
        grid-template-columns: 2fr 1fr;
    }
    
}

@media screen and (max-width: 755px){
    .priorities-container h2{
        font-size:  1.5em;
        margin: 0;
    }
    .feature-right h2{
        font-size: 1.6em;
    }
    .feature-right h4{
        font-size: 1.2em;
    }
    .feature-right p{
        font-size: 1em;
        padding: 5px;
    }
    .priorities-wrapper ul li{
        width: 150px;
        height: 150px;
    }
    .priorities-wrapper li img{
        width: 70px;
        padding: 5px;
    }
    .priorities-wrapper li h4{
        font-size: 0.8em;
    }
}

@media screen and (max-width: 600px){
    .priorities-container{
        padding: 0;
    }
    .feature-left{
        padding: 0;
        margin: 0;
    }
    .feature-left .feature-image{
        padding: 0;
        box-shadow: none;
        border-radius: 0;
        margin: 0;
        margin-block: 1rem;
        min-width: 100vw;
    }
    .priorities-container h2{
        font-size: 2.5em;
        text-align: center;
    }
    .feature-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }
    .feature-wrapper .feature-right{
        align-items: center;
        text-align: center;
    }
    .priorities-wrapper h3{
        text-align: center;
    }
    .priorities-wrapper ul{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 420px){
    .priorities-container{
        padding: 5px;
    }
}

@media screen and (max-width: 350px) {
    .priorities-wrapper ul li{
        width: 120px;
        height: 120px;
    }
    .priorities-wrapper li img{
        height: 40px;
        width: 40px;
        padding: 0;
    }
    .priorities-wrapper li h4{
        font-size: 0.65em;
    }
}