@media screen and (max-width: 1150px) {
    .banner-blur{
        width: 70%;
    }
}

@media screen and (max-width: 850px) {
    .banner-content h1{
        font-size: 4em;
    }
    .banner-content h2{
        font-size: 2em;
    }
}

@media screen and (max-width: 710px) {
    .banner-blur{
        width: 80%;
    }
    .banner-content h1{
        font-size: 3.5em;
    }
    .banner-content h2{
        font-size: 1.5em;
    }
}

@media screen and (max-width: 550px) {
    .banner-content h1{
        font-size: 3em;
    }
    .banner-content h2{
        font-size: 1.4em;
    }
}

@media screen and (max-width: 480px) {
    .banner-content h1{
        width: 80%;
        text-align: center;
    }
}

@media screen and (max-width: 360px) {
    .banner-content h1{
        font-size: 2.5em;
    }
    .banner-content h2{
        font-size: 1.2em;
        width: 80%;
        text-align: center;
    }
}