@media screen and (max-width: 1120px) {
    .places-container h1{
        font-size: 2.5em;
    }
    .places-container h2{
        font-size: 1.4em;   
    }
    .places-container p{
        font-size: 0.8em;
    }
}

@media screen and (max-width: 1000px) {
    .centennial{
        width: 80%;
    }
    .boulevard{
        width: 80%;
    }
    .marina{
        width: 80%;
    }
    .oldfort{
        width: 80%;
    }
    .kekabekka{
        width: 80%;
    }
}

@media screen and (max-width: 850px) {
    .centennial img{
        width: 200px;
        height: 200px;
        align-self: center;
    }
    .centennial-info{
        width: 100%;
    }
    .boulevard img{
        width: 200px;
        height: 200px;
        align-self: center;
    }
    .boulevard-info{
        width: 100%;
    }
    .marina img{
        width: 200px;
        height: 200px;
        align-self: center;
    }
    .marina-info{
        width: 100%;
    }
    .oldfort img{
        width: 200px;
        height: 200px;
        align-self: center;
    }
    .oldfort-info{
        width: 100%;
    }
    .kekabekka img{
        width: 200px;
        height: 200px;
        align-self: center;
    }
    .kekabekka-info{
        width: 100%;
    }
}

@media screen and (max-width: 625px) {
    .centennial{
        height: 100%;
        flex-direction: column;
        gap: 5px;
    }
    .boulevard{
        height: 100%;
        flex-direction: column;
        gap: 5px;
    }
    .marina{
        height: 100%;
        flex-direction: column;
        gap: 5px;
    }
    .oldfort{
        height: 100%;
        flex-direction: column;
        gap: 5px;
    }
    .kekabekka{
        height: 100%;
        flex-direction: column;
        gap: 5px;
    }
}

@media screen and (max-width: 550px){
    .places-container h1{
        font-size: 2em;
        padding: 0;
    }
} 
