@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;500;600;700;800&family=Saira:wght@100;200;300;400;500;600;700;800&display=swap');
:root{
  --white: #fff;
  --lightgreen:#CDD5C7;
  --darkgreen:#273312;
  --lightbrown:#7B8142;
  --darkbrown:#67552E;
  --lightblue: #3f91d4;
  --button:#33b249;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  color: #1D2911; 
}
body, html{
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Saira', sans-serif;
}

@media screen and (max-width: 310px) {
  body, html{
    display: block;
  }
}

