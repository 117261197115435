.priorities-container{
    height: 100%;
    min-height: 80vh;
    width: 100vw;
    position: relative;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    margin-top: -1px;
}

.priorities-container h2{
    padding: 20px;
    font-size: 3rem;
    margin-top: 30px;
}
.priorities-container span{
    color: #3f91d4;
}
.feature-wrapper{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 0px 0px;
    justify-items: center;
    padding: 20px;
    margin-top: 10px;
    position: relative;
}
.feature-wrapper .feature-left{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    
}
.feature-left .feature-image{
    width: 100%;
    height: 100%;
    min-height: 50vh;
    background-image: url(../../assets/Strathcona-Sign.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 0 10px 12px var(--white) inset; 
}
.feature-right{
    height: 100%;
    width: 100%;
    max-width: 600px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.feature-right h2{
    padding: 0;
    margin: 0;
    font-size: 2.5em;
    font-weight: 500;
}
.feature-right h4{
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0.3em;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.feature-right p{
    font-size: 1.2em;
    line-height: 1.4em;
    font-weight: 400;
}
.feature-right button{
    width: 200px;
    height: 50px;
    background: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 1rem;
    border: 1px solid #1D2911;
    transition: 0.3s ease-in-out;
}
.feature-right button a{
    color: #1D2911;
}
.feature-right button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.priorities-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20PX;
    margin-bottom: 100px;
  }
  .priorities-wrapper h3{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2rem;
    text-transform: capitalize;
  }
  .priorities-wrapper ul{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    
    grid-gap: 20px;
  }
  .priorities-wrapper ul li{
    text-align: center;
    grid-area: auto;
    overflow-wrap: break-word;
    padding: 10px;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 5px solid var(--lightblue);
    background: var(--lightblue);
  }
  .priorities-wrapper li img{
    width: 100px;
    padding: 10px;
  }
  .priorities-wrapper li h4{
    color: var(--white);
  }

.custom-shape-divider-bottom-1662139897 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -1px;
}

.custom-shape-divider-bottom-1662139897 svg {
    position: relative;
    display: block;
    width: calc(150% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1662139897 .shape-fill {
    fill: var(--lightgreen);
}
