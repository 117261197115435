@media screen and (max-width: 1050px) {
    .hours-right h3{
        font-size: 3rem;
        padding: 20px;
    }
}

@media screen and (max-width: 970px) {
    .hours-right h3{
        font-size: 2em;
    }
    .hours-right h4{
        font-size: 1.2em;
    }
} 

@media screen and (max-width: 830px) {
    .hours-right h3{
        font-size: 1.5em;
    }
    .hours-right h4{
        font-size: 1em;
    }
} 

@media screen and (max-width: 670px) {
    .hours-container{
        height: 100%;
    }
    .hours-top{
        flex-direction: column;
        gap: 0;
        align-items: center;
    }
    .hours-left{
        width: 100%;
        border: none;
    }
    .hours-right{
        width: 100%;
        padding-bottom: 50px;
    }
    .hours-right h3{
        font-size: 2.5em;
    }
    .hours-right h4{
        font-size: 2em;
    }
}

@media screen and (max-width: 470px) {
    .hours-right h3{
        font-size: 2rem;
    }
    .hours-right h4{
        font-size: 1.5em;
        padding-bottom: 2rem;
    }
    .hours-left{
        flex-direction: column;
        padding: 50px;
    }
}

@media screen and (max-width: 330px) {
    .hours-left{
        align-items: flex-start;
        justify-content: flex-end;
        padding: 50px;
        margin-left: 20px;
    }
    .hours-right h3{
        padding: 0;
        font-size: 1.4em;
    }
    .hours-right h4{
        font-size: 1em;
    }
}