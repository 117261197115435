.hours-container{
    height: 50vh;
    width: 100%;
    background-color: var(--lightgreen);
}
.hours-top{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
}
.hours-left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px;
    gap:0;
    text-align: center;
    border-left: 3px solid var(--darkgreen);
}
.hours-left h2{
    font-size: 3rem;
    padding: 1rem;
}
.hour-times{
    height: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.hour-times h3{
    font-size: 1.5em;
}
.hours-right{
    width: 50%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.hours-right h3{
    font-size: 3em;
}
.hours-right h4{
    font-size: 1.5em;
}
.hours-right button{
    height: 50px;
    width: 200px;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.2em;
    border: none;
    background: var(--lightblue);
    color: var(--white);
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.hours-right button:hover{
    transform: scale(1.1);
}
.hours-right button a{
    color: var(--white);
}