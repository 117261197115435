.our-story-container{
    width: 100%;
    height: 100%;
    position: relative;
}
.our-story-top{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.our-story-top h2{
    width: 100%;
    font-size: 4em;
    font-weight: 300;
    letter-spacing: 2px;
    padding: 20px;
}
.our-story-bottom{
 height: 1100px;
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
 position: relative;
}
.our-story-bottom .our-story-card{
    min-height: 400px;
    width: 700px;
    background-color: var(--lightgreen);
    padding: 40px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0 0 10px 10px var(--white) inset;
}
.our-story-card h2{
    font-size: 3em;
    font-weight: 300;
}
.our-story-card h3{
    font-size: 2em;
    font-weight: 500;
}
.our-story-card p{
    font-size: 1em;
    letter-spacing: 1.5px;
}
.our-story-card button{
    padding: 10px 20px;
    background-color: var(--lightblue);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: var(--white);
    margin: 10px;
    transition: 0.3s ease-in-out;
}
.our-story-card button:hover{
    transform: scale(1.1);
}
.our-story-card span{
    color: var(--lightblue);
}

#beginning{
    position: absolute;
    right: 50%;
}
#starting{
    position: absolute;
    top: 50px;
    left: 50%;
}
#race{
    position: absolute;
    right: 50%;
    top: 450px;
}
#onwards{
    position: absolute;
    left: 50%;
    top: 650px;
}

.our-story-banner{
    height: 250px;
    min-width: 100vw;
    background-image: url(https://www.strathconamotel.com/assets/public/images/jaime-dantas-X4ncleupFMc-unsplash.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}