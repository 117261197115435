


.places-container{
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.places-container h2{
    font-size: 2em;
    color: var(--white);
}
.places-container h5{
    font-size: 1em;
    color: var(--white);
}
.places-container p{
    font-size: 1em;
    color: var(--white);
}
.background-vid{
    height: 100%;
    width: 100%;
    position: relative;
}
.background-vid img{
    height: 100%;
    width: 100%;
    z-index: -1;
    position: fixed;
    filter: opacity(50%);
}

.places-top{
    height: 100%;
    width: 100%;
    padding: 50px;
    margin: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'K2D', sans-serif;
}

.places-top h1{
    font-size: 3em;
    text-align: center;
    color: var(--darkgreen);
    padding: 20px;
}
.places-bottom{
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.centennial{
    height: 300px;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 100px;
    background-color: rgba(0, 0, 0, 0.70);
}
.centennial img{
    width: 300px;
    border-radius: 10px;
}
.centennial-info{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.boulevard{
    height: 300px;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 100px;
    background-color: rgba(0, 0, 0, 0.70);
}
.boulevard img{
    width: 300px;
    border-radius: 10px;
}
.boulevard-info{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.marina{
    height: 300px;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    background-color: rgba(0, 0, 0, 0.70);
}
.marina img{
    width: 300px;
    border-radius: 10px;
}
.marina-info{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.oldfort{
    height: 300px;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 100px;
    background-color: rgba(0, 0, 0, 0.70); 
}
.oldfort img{
    width: 300px;
    border-radius: 10px;
}
.oldfort-info{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.kekabekka{
    height: 300px;
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    background-color: rgba(0, 0, 0, 0.70);
    margin-bottom: 50px;
}
.kekabekka img{
    width: 300px;
    border-radius: 10px;
}
.kekabekka-info{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

