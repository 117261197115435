.footer-container{
    height: 100%;
    width: 100%;
    background-color: #3f91d47e;
}
.footer-top{
display: flex;
align-items: center;
justify-content: center;
}
.footer-top h2{
    padding: 10px;
}
.footer-top button{
    background: none;
    border: 1px var(--darkgreen) solid;
    font-size: 3em;
    color: var(--lightblue);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    padding: 0 auto;
}
.footer-top button:hover{
    transform: scale(1.1);
}
.footer-bottom{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: var(--lightblue);
}
.footer-bottom h2{
    width: 100%;
    text-align: right;
    padding: 10px;
    font-size: 1em;
}
.footer-bottom h3{
    width: 100%;
    text-align: left;
    padding: 10px;
    font-size: 1em;
}