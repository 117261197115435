.contact-container{
    height: 100%;
    width: 100vw;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff6f ;
    background-blend-mode:overlay;
}
.contact-top{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    font-family: 'K2D', sans-serif;
}
.contact-top h1{
    color: var(--darkgreen);
    font-size: 4rem;
}
.contact-bottom{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}
.contact-left{
    height: 100%;
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
}
.contact-left h2{
    font-size: 3em;
}
.contact-left .strath-phone{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.contact-left .strath-phone h3{
    padding: 5px;
    font-size: 1.6em;
}
.contact-left .strath-phone a{
    font-size: 1.2em;
    font-weight: 600;
    padding: 5px;
    cursor: pointer;
}
.contact-left .strath-phone h4{
    font-size: 1.2em;
    font-weight: 600;
    padding: 5px;
}
.contact-left .strath-email h3{
    font-size: 1.6em;
}
.contact-left .strath-email h4{
    font-size: 1.2em;
    font-weight: 600;
}
.contact-left .strath-address h3{
    font-size: 1.6em;
}
.contact-left .strath-address p{
    font-size: 1.2em;
    font-weight: 600;
}
.contact-left .strath-hours h3{
    font-size: 1.6em;
}
.contact-left .strath-hours p{
    font-size: 1.2em;
    font-weight: 600;
}
.contact-right{
    height: 100%;
    width: 50%;
    text-align: center;
    display: flex;
}
.contact-right form{
    height: 100%;
    width: 100%;
    padding: 50px;
    padding-bottom: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--lightgreen);
}
.contact-right form label{
    color: var(--darkgreen);
    font-size: 1.2em;
    align-self: flex-start;
    width: 100%;
}
.contact-right form input{
    width: 400px;
    height: 30px;
    margin-bottom: 30px;
    background: rgba(0, 0, 0, 0.256);   
    color: var();
    font-size: 1.2rem;
    border: none;
}
input, select, textarea { 
    color: var(--white);
    text-align: center;
    padding: 5px;
}
textarea, select, textarea{
    text-align: start;
    color: white;
}
.contact-right form textarea{
    width: 400px;
    height: 200px;
    margin-bottom: 30px;
    background: rgba(0, 0, 0, 0.256);   
    border: none;
}
.contact-right .submit-button{
    width: 100px;
    height: 50px;
    font-size: 1em;
    border: none;
    background-color: var(--lightblue);
    color: var(--white);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.contact-right .submit-button:hover{
    transform: scale(1.1);
}